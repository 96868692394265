import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/layout/layout.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/home/home.vue')
        },
        {
          path: '/product',
          name: '产品中心',
          component: () => import('@/views/product/product.vue'),
          children: [
            {
              path: 'standard',
              name: '高校实验室安全规范管理平台',
              component: () => import('@/views/product/aqgfManageSystem.vue')
            },
            {
              path: 'wise',
              name: '智慧实验室管理平台',
              component: () => import('@/views/product/zhsysManagePlatform.vue')
            },
            {
              path: 'lims',
              name: '实验室信息管理系统LIMS',
              component: () => import('@/views/product/limsSystem.vue')
            },
            {
              path: 'classify',
              name: '实验室安全分类分级管理系统',
              component: () => import('@/views/product/aqflfjManageSystem.vue')
            },
            {
              path: 'synthetical',
              name: '实验室综合管理平台',
              component: () => import('@/views/product/labManagePlatform.vue')
            },
            {
              path: 'hazardous',
              name: '实验室危化品管理系统',
              component: () => import('@/views/product/whpManageSystem.vue')
            },
            {
              path: 'instrument',
              name: '仪器预约共享管理系统',
              component: () => import('@/views/product/yqyuyuegxManageSystem.vue')
            },
            {
              path: 'approve',
              name: '实验室安全教育考试准入系统',
              component: () => import('@/views/product/kszhunruManageSystem.vue')
            },
            {
              path: 'monitor',
              name: '实验室智能环境监测系统',
              component: () => import('@/views/product/znhjDetectionSystem.vue')
            },
            {
              path: 'inspect',
              name: '实验室安全检查管理系统',
              component: () => import('@/views/product/aqjcManageSystem.vue')
            },
            {
              path: 'reagent',
              name: '试剂耗材管理系统',
              component: () => import('@/views/product/sjhcManageSystem.vue')
            },
            {
              path: 'reservation',
              name: '实验室开放预约管理系统',
              component: () => import('@/views/product/kfyyManageSystem.vue')
            },
            {
              path: 'education',
              name: '实验/实践教学管理系统',
              component: () => import('@/views/product/syjxManageSystem.vue')
            },
            {
              path: 'report',
              name: '实验室数据上报管理系统',
              component: () => import('@/views/product/sjsbManageSystem.vue')
            },
            {
              path: 'device',
              name: '资产设备管理系统',
              component: () => import('@/views/product/zcsbManageSystem.vue')
            },
            {
              path: 'sample',
              name: '生物样本库管理系统',
              component: () => import('@/views/product/swybkManageSystem.vue')
            },
            {
              path: 'project',
              name: '实验室建设项目管理系统',
              component: () => import('@/views/product/jsxmManageSystem.vue')
            },
            {
              path: 'safety',
              name: '实验室安全管理系统',
              component: () => import('@/views/product/sysaqManageSystem.vue')
            },
          ]
        },
        {
          path: '/case',
          name: '客户案例',
          component: () => import('@/views/case/case.vue'),
          redirect: '/case/college',
          children: [
            {
              path: 'college',
              name: '高校',
              component: () => import('@/views/case/college.vue')
            },
            {
              path: 'institute',
              name: '科研院所',
              component: () => import('@/views/case/institute.vue')
            },
            {
              path: 'enterprise',
              name: '医院/企业',
              component: () => import('@/views/case/enterprise.vue')
            },
            {
              path: 'classic',
              name: '案例展示',
              component: () => import('@/views/case/classic.vue'),
            },
          ]
        },
        {
          path: '/cooperate',
          name: '合作共赢',
          component: () => import('@/views/cooperate/cooperate.vue'),
          redirect: '/cooperate/dealer',
          children: [
            {
              path: 'dealer',
              name: '我是经销商',
              component: () => import('@/views/cooperate/comp/dealer.vue')
            },
            {
              path: 'business',
              name: '我是厂商',
              component: () => import('@/views/cooperate/comp/business.vue')
            }
          ]
        },
        {
          path: '/news',
          name: '新闻中心',
          component: () => import('@/views/news/news.vue'),
          redirect: '/news/company',
          children: [
            {
              path: 'company',
              name: '公司新闻',
              component: () => import('@/views/news/newsCompany.vue')
            },
            {
              path: 'industry',
              name: '行业新闻',
              component: () => import('@/views/news/newsIndustry.vue')
            }
          ]
        },
        {
          path: '/about',
          name: '关于我们',
          component: () => import('@/views/about/about.vue'),
          redirect: '/about/company',
          children: [
            {
              path: 'company',
              name: '公司介绍',
              component: () => import('@/views/about/comp/aboutCompany.vue')
            },
            {
              path: 'honor',
              name: '荣誉资质',
              component: () => import('@/views/about/comp/aboutHonor.vue')
            },
            {
              path: 'contact',
              name: '联系我们',
              component: () => import('@/views/about/comp/aboutContact.vue')
            },
          ]
        },
        {
          path: '/news/detail/:id',
          name: '新闻详情',
          component: () => import('@/views/news/newsDetail.vue')
        },
        {
          path: '/case/detail',
          name: '案例详情',
          component: () => import('@/views/case/caseDetail.vue')
        },
      ]
    }
  ]
})
router.beforeEach((to, from, next) => {
  next();
})
export default router