<template>
  <section class="breadcrumb_box">
    <section class="container">
      <a href="/">首页</a>
      <section>
        <i class="el-icon-arrow-right icon-arrow"></i>
        <a v-if="secondPath" :href="secondPath">{{second}}</a>
        <section v-else class="noclick">{{second}}</section>
      </section>
      <section>
        <i class="el-icon-arrow-right icon-arrow"></i>
        <section class="thrid">{{thirdName?thirdName:third}}</section>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  components: {},
  name: 'breadcrumb',
  props: {
    thirdName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      second: '',
      third: '',
      secondPath: '',
      secDisaledProductList: ['实验室综合管理平台', '高校实验室安全规范管理平台', '实验室安全分类分级管理系统', '实验室信息管理系统LIMS', '试剂通- SAAS版']
    }
  },
  mounted() {
    const route = this.$route
    const secondName = route.matched[1].name
    this.second = secondName
    this.third = route.name
    if (secondName === '产品中心') {
      this.secondPath = this.secDisaledProductList.indexOf(route.name) !== -1 ? '' : '/product/synthetical'
    }
    if (secondName === '新闻详情') {
      this.second = '新闻中心'
      this.secondPath = '/news/company'
    }
    if (secondName === '案例详情') {
      this.second = '客户案例'
      this.secondPath = '/case/college'
    }
  },
  methods: {
  },
  watch: {
    $route(to, from) {
      this.third = to.name
    },
  },
}
</script>

<style lang='scss' scoped>
a {
  font-size: 16px !important;
  color: #303133;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: #308dff;
  }
}
.breadcrumb_box {
  width: 100%;
  background: #fbfbff;
  height: 94px;
  border-bottom: 1px solid #dde6ed;
  vertical-align: top;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.noclick {
  color: #6d6d6d;
  font-weight: normal;
  display: inline-block;
}
.thrid {
  color: #308dff;
  font-weight: normal;
  display: inline-block;
}
.icon-arrow {
  margin: 0 6px;
}
</style>
